import { hash } from 'ohash';

/**
 * Shop Store
 * ----------------------------
 *
 * The shop store contains all data related to the products, offers and quotes
 * a customer selects to buy during the booking flow.
 */
export const useShopStore = defineStore('shop', () => {
    /**
     * Products
     * ------------------------------------------------------------------------------------------
     */

    const products = ref<Products>({});

    // stores a hash for each product, so we can easily watch it and detect changes in
    // any of the product specifications
    const hashedProducts = computed(() =>
        Object
            .keys(products.value)
            .reduce<Record<ProductKey, string>>(
                (acc, key) => {
                    acc[key as ProductKey] = hash(products.value[key as ProductKey]);
                    return acc;
                },
                {} as Record<ProductKey, string>,
            ),
    );

    // the product for the current active booking flow
    const currentFlowProduct = computed(() => {
        const flowStore = useFlowStore();
        return products.value[flowStore.productConfig?.key as ProductKey];
    });

    function addProductActivity(product: ProductActivity) {
        resetActivities();
        products.value[product.key] = product;
    }

    function removeProduct(key: ProductKey) {
        delete products.value[key];
    }

    /**
     * Offers
     * ------------------------------------------------------------------------------------------
     */
    const offers = ref<Offers>({});
    const isReactiveOfferLoading = ref(false);

    // the offer for the current active booking flow
    const currentFlowOffer = computed(() => {
        const flowStore = useFlowStore();
        return offers.value[flowStore.productConfig?.key as ProductKey];
    });

    // If any of the product specification changes, reload the offer (only for that product)
    watch(hashedProducts, (newValue, oldValue) => {
        if (!isReactiveOfferLoading.value) {
            return;
        }
        Object.keys(newValue).forEach((key) => {
            if (oldValue[key as ProductKey] !== newValue[key as ProductKey]) {
                loadOffer(key as ProductKey);
            }
        });
    });

    const { isLoading } = useLoading();

    /**
     * load offer for specific product
     */
    async function loadOffer(key: ProductKey) {
        isLoading.value = true;
        const product = products.value[key]!;
        const passengers = usePassengerStore().passengers.filter(passenger => product.passengerRefs.includes(passenger.ref));

        // If there are no passengers, remove the offer, as offers without passengers have no meaning
        if (!product.passengerRefs.length) {
            delete offers.value[key];
            isLoading.value = false;
            return;
        }

        let offer;
        if (product.type === 'activity') {
            ({ data: offer } = await apiLoadActivityOffer(product, passengers));
        }

        if (offer) {
            offers.value[key] = offer;
        }
        else {
            delete offers.value[key];
        }
        isLoading.value = false;
        return true;
    }

    /**
     * Quote
     * ------------------------------------------------------------------------------------------
     */
    const quote = ref<Quote>();
    const isReactiveQuoteLoading = ref(false);

    // If any of the offers change, reload the quote, so we always have up-to-date prices
    watch(offers, () => {
        if (!isReactiveQuoteLoading.value) {
            return;
        }

        loadQuote();
    }, { deep: true });

    /**
     * load quote for all offers
     */
    async function loadQuote() {
        ({ data: quote.value } = await apiLoadQuote(Object.values(offers.value), usePassengerStore().currentFlowPassengers));
        return true;
    }

    /**
     * ------------------------------------------------------------------------------------------
     */

    function resetActivities() {
        const activityKeys = configProducts.filter(pc => pc.type === 'activity').map(pc => pc.key);
        products.value = Object.fromEntries(Object.entries(products.value).filter(([key]) => !activityKeys.includes(key as ProductKey)));
        offers.value = Object.fromEntries(Object.entries(offers.value).filter(([key]) => !activityKeys.includes(key as ProductKey)));
    }

    function reset() {
        products.value = {};
        offers.value = {};
        isReactiveOfferLoading.value = false;
        quote.value = undefined;
        isReactiveQuoteLoading.value = false;
    }

    return {
        // Products
        products,
        hashedProducts,
        currentFlowProduct,
        addProductActivity,
        removeProduct,

        // Offers
        offers,
        isReactiveOfferLoading,
        currentFlowOffer,
        loadOffer,

        // Quote
        quote,
        isReactiveQuoteLoading,
        loadQuote,

        reset,
    };
}, {
    persist: {
        omit: [
            'isReactiveOfferLoading',
            'isReactiveQuoteLoading',
        ],
        ...storePersist.storePersistOptions,
    },
});
