import { hash } from 'ohash';

/**
 * Passenger Store
 * ----------------------------
 *
 * This store manages the passengers, separated from bookable products (activities, trips, accommodation etc.)
 * Each passenger equals a person and can be part of one or more products.
 */
export const usePassengerStore = defineStore('passenger', () => {
    const passengers = ref<Passenger[]>([]);

    // Currently, the system does not (yet) allow specfic passengers per product, so whenever passengers are updated,
    // they are updated or added to all products
    watch(
        passengers,
        () => Object.values(useShopStore().products).forEach(product => product.passengerRefs = passengers.value.map(p => p.ref)),
        { deep: true },
    );

    // the passengers for the current active booking flow
    const currentFlowPassengers = computed(() => passengers.value.filter(p => useShopStore().currentFlowProduct?.passengerRefs.includes(p.ref)));

    /**
     *
     * Creates a unique reference for passenger. Whenever data of the passenger is changed
     * that is relevant for offer calculation, the reference is updated.
     */
    function getPassengerRef(passenger: Passenger) {
        return `passenger-${hash([
            passenger.uuid,
            passenger.dateOfBirth,
            passenger.keycard,
            passenger.reductionCards,
        ])}`;
    }

    /**
     * Adds a new passenger to the store.
     * For each passenger, a unique UUID is generated.
     * The "ref" can later change, in case the passenger data changes.
     */
    function addPassenger(passenger: Passenger) {
        const p = {
            ...passenger,
            uuid: crypto.randomUUID(),
        };
        p.ref = getPassengerRef(p);
        passengers.value.push(p);
        analyticsTrackEcommerce('add_to_cart', {

        });
    }

    /**
     * Updates passenger data. Make sure ref is regenerated.
     */
    function updatePassenger(passenger: Passenger) {
        const i = passengers.value.findIndex(p => p.ref === passenger.ref);
        passengers.value[i] = {
            ...passenger,
            ref: getPassengerRef(passenger),
        };
    }

    /**
     * Removes passengers from the store
     */
    function removePassenger(passenger: Passenger) {
        const i = passengers.value.findIndex(p => p.ref === passenger.ref);
        passengers.value.splice(i, 1);

        analyticsTrackEcommerce('remove_from_cart', {

        });
    }

    function reset() {
        passengers.value = [];
    }

    return {
        passengers,
        currentFlowPassengers,

        addPassenger,
        updatePassenger,
        removePassenger,
        reset,
    };
}, {
    persist: storePersist.storePersistOptions,
});
