type ResultType = Quote | undefined;
type ApiResponseType = ApiSchema['Quote'];
type ParamsType = ApiSchema['BookingRequest'];

/**
 * Loads the quote for the given offers and passengers.
 * The quote contains the final price for all given offers combined.
 */
export default async function apiLoadQuote(offers: Offer[], passengers: Passenger[]): Promise<ApiResult<ResultType>> {
    try {
        return await callApiPost<ResultType, ApiResponseType, ParamsType>(
            '/quotes',

            // Request body
            {
                offers: offers.map(o => ({
                    offerId: o.offerId,
                    passengerRefs: o.passengerRefs,
                })),
                passengerSpecifications: passengers.map(p => ({
                    externalRef: p.ref,
                    type: 'PERSON',
                    dateOfBirth: formatDateTimeISO(p.dateOfBirth),
                    cards: p.reductionCards ?? [],
                })),
            },
            // Transform result
            (response: ApiResponseType) => ({
                price: response.price,
            } satisfies ResultType),
        );
    }
    catch (error) {
        handleError(error);
        return {
            ok: false,
            warnings: [],
            data: undefined,
        };
    }
}
